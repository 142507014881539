import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import routes from './routes';

export default function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Col
      xs={12}
      className='bg-primary position-fixed p-0 m-0'
      style={{ zIndex: 100 }}
    >
      <Container>
        <Row className='mb-0'>
          <Col>
            <Navbar expand='sm' dark className='m-0 p-0'>
              <NavbarToggler
                onClick={toggle}
                className='text-light ml-auto my-3 border border-light'
              />
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                  {routes.map((route) => (
                    <NavItem
                      key={route.path}
                      className='m-0 nav-item my-3 ml-auto'
                    >
                      <NavLink
                        to={route.path}
                        activeClassName='bg-secondary'
                        className='text-light text-decoration-none p-3 m-0'
                        style={{ height: '3rem' }}
                      >
                        {route.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}
