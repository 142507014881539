import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';

import { BsPhone, BsEnvelopeOpen } from 'react-icons/bs';

export default function Contact() {
  return (
    <>
      <ContactIcons />
      <SendRequest />
    </>
  );
}

function SendRequest() {
  return (
    <Container className='my-4 p-4 bg-secondary'>
      <h2 className='text-light'>Anfrage Senden</h2>
      <Form method='POST' action='/sendRequest'>
        <FormGroup>
          <Label for='firstName'>Vorname</Label>
          <Input
            type='text'
            name='firstName'
            id='firstName'
            placeholder='Hans'
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for='lastName'>Nachname</Label>
          <Input
            type='text'
            name='lastName'
            id='lastName'
            placeholder='Muster'
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for='phone'>Telefon</Label>
          <Input
            type='tel'
            name='phone'
            id='phone'
            placeholder='+41 '
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for='email'>Email</Label>
          <Input
            type='email'
            name='email'
            id='email'
            placeholder='hans.muster@gmail.com'
            required
          />
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={6}>
              <Label for='date'>Datum von</Label>
              <Input
                type='date'
                name='date'
                id='date'
                placeholder='12.03.2021'
                required
              />
            </Col>
            <Col xs={6}>
              <Label for='date'>Datum bis</Label>
              <Input
                type='date'
                name='date'
                id='date'
                placeholder='12.03.2021'
                required
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label for='numPersons'>Anzahl Personen</Label>
          <Input
            type='number'
            name='numPersons'
            id='numPersons'
            placeholder='4'
            min='1'
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for='infos'>Bemerkungen</Label>
          <Input type='textarea' name='infos' id='infos' />
        </FormGroup>
        <Button color='dark' block>
          Anfrage Senden
        </Button>
      </Form>
    </Container>
  );
}

function ContactIcons() {
  return (
    <Container className='p-0 my-4'>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <Col className='p-2 bg-secondary'>
            <Row>
              <Col
                xs={4}
                className='text-right pb-1'
                style={{ fontSize: '3.5rem' }}
              >
                <BsPhone />
              </Col>
              <Col className='p-0 pt-3'>
                <h3 className='text-light'>Telefon</h3>
                <p className='m-0'>
                  <a href='tel:+41793645404'>+41 79 364 54 04 </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Col>

        <Col xs={12} sm={12} md={6}>
          <Col className='p-2 bg-secondary'>
            <Row>
              <Col
                xs={4}
                className='text-right pb-1'
                style={{ fontSize: '3.5rem' }}
              >
                <BsEnvelopeOpen />
              </Col>
              <Col className='p-0 pt-3'>
                <h3 className='text-light'>Email</h3>
                <p className='m-0'>
                  <a href='mailto:barbara.egger@tbwil.ch'>
                    barbara.egger(at)tbwil.ch
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
