import React from "react"
import Carousel from "react-gallery-carousel"
import "react-gallery-carousel/dist/index.css"

function importAll(r) {
	return r.keys().map(item => {
		const src = r(item)
		return { src }
	})
}

const images = importAll(require.context("../../images/photos"))

export default function Photogallery() {
	return <Carousel images={images} isAutoPlaying isLoop shouldMaximizeOnClick style={{ height: "50vh" }} />
}
