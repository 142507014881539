import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { BsArrowRightShort } from 'react-icons/bs';

export default function Home() {
  return (
    <>
      <div className='quote'>
        „Mir ist’s unter allen Gegenden, die ich kenne, die liebste und
        interessanteste.“
        <span className='mt-2'>Goethe über die Ferienregion Andermatt</span>
      </div>
      <Container className='my-3'>
        <Row className='mb-4'>
          <Col xs={12} md={4} className='my-2'>
            <div className='bg-secondary w-100 h-100 p-4'>
              <p>
                Praktisch und ruhig liegt die Wohnung am Fusse des Gemsstocks -
                alles ist zu Fuss gut erreichbar.
              </p>

              <Link to='/info'>
                <BsArrowRightShort style={{ marginTop: '-5px' }} /> Infos zu der
                Wohnung
              </Link>
            </div>
          </Col>
          <Col xs={12} md={4} className='my-2'>
            <div className='bg-secondary w-100 h-100 p-4'>
              <p>
                Eine Wohnung zum Wohlfühlen & Geniessen - allein, zu zweit, für
                eine oder zwei Familien.
              </p>
              <Link to='/photos'>
                <BsArrowRightShort style={{ marginTop: '-5px' }} /> Fotos der
                Wohnung
              </Link>
            </div>
          </Col>

          <Col xs={12} md={4} className='my-2'>
            <div className='bg-secondary w-100 h-100 p-4'>
              <p>
                Andermatt mit seinen imposanten nahen Bergen bietet dennoch
                Weitblick in die Täler - ein Traum!
              </p>
              <a href='https://www.andermatt.ch/'>
                <BsArrowRightShort style={{ marginTop: '-5px' }} /> Webseite
                Tourismus Andermatt{' '}
                <FiExternalLink style={{ marginTop: '-5px' }} />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            Geniessen Sie ein paar herrliche Urlaubstage! Die urbane Bergwelt,
            die frische Alpinluft und vielfältige Bewegungs- und
            Genussmöglichkeiten werden Sie begeistern. Und sollten sie nach
            einer strengen Zeit etwas ausruhen wollen; holen Sie auf dem Balkon
            des Gemsstock-Home die Sonnenstrahlen solange ein, bis sie hinter
            den Bergen untergeht.
          </Col>
        </Row>
        <Row className='my-5'>
          <Col>
            <Link to='/contact'>
              <Button className='bg-dark' block>
                Jetzt Wohnung buchen
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
