import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PhotoGalery from './Photogalery';

export default function Photos() {
  return (
    <Container className='my-5'>
      <Row className='justify-content-center'>
        <Col
          xs={12}
          lg={8}
          xl={6}
          className='text-dark d-flex justify-content-center m-0 p-0'
        >
          <PhotoGalery />
        </Col>
      </Row>
    </Container>
  );
}
