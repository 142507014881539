import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './routes';

export default function ContentRoutes() {
  return (
    <Switch>
      {routes.map((route) => {
        const C = route.component;
        return (
          <Route key={route.path} path={route.path}>
            <C name={route.name} />
          </Route>
        );
      })}
      <Redirect to='/home' />
    </Switch>
  );
}
