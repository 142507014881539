import Home from "../Home/Home";
import Contact from "../Contact/Contact";
import Infos from "../Infos/Infos";
import Photos from "../Photos/Photos";

const routes = [
  { name: "Home", component: Home, path: "/home" },

  {
    name: "Infos",
    component: Infos,
    path: "/info",
  },
  {
    name: "Fotos",
    component: Photos,
    path: "/photos",
  },
  {
    name: "Kontakt",
    component: Contact,
    path: "/contact",
  },
];

export default routes;
