import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import infos from './infoData';

export default function Infos() {
  return (
    <Container style={{ marginTop: '-5rem' }} className='justify-items-center'>
      <Row xs={12} className='px-3'>
        {infos.map((info) => {
          const Icon = info.icon;
          return (
            <Col key={info.title} xs={12} md={6} lg={4} className='p-2'>
              <Col className='p-2 bg-secondary h-100'>
                <Row className='align-items-center pb-2'>
                  <Col
                    xs={4}
                    className='w-100 text-center'
                    style={{ fontSize: '3.2rem' }}
                  >
                    <Icon />
                  </Col>
                  <Col xs={8} className='align-middle pl-0'>
                    <h4 className='text-light text-left m-0'>{info.title}</h4>
                  </Col>
                </Row>

                <p className='p-3 m-0'>
                  {info.text.split('<br/>').map((str, i) => (
                    <span key={i}>
                      {str}
                      <br />
                    </span>
                  ))}
                </p>
              </Col>
            </Col>
          );
        })}
      </Row>
      <footer className='icon-impressum'>
        <div>
          Icons erstellt von
          <a href='https://www.freepik.com' title='Freepik'>
            {' '}
            Freepik{' '}
          </a>
          from{' '}
          <a href='https://www.flaticon.com/de/' title='Flaticon'>
            www.flaticon.com
          </a>
        </div>
      </footer>
    </Container>
  );
}
