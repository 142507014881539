import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import Dropdown from './Dropdown';
import ContentRoutes from './ContentRoutes';

function Main() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className='m-0 p-0 bgImage'>
            <Dropdown />
          </Col>
        </Row>
        <Row>
          <Col className='m-0 p-0'>
            <ContentRoutes />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withRouter(Main);
